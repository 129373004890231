/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ServerProps } from '../../config/server.config';
import ContainerLoad from '../../tools/components/general/ContainerLoad';
import { Table } from 'tc-minibox';
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { icons_config } from '../../config/context.config';
import { UserModel } from '../../database/user-model.db';
import { useDispatch } from 'react-redux';
import { addRequestStatus, setLoaderStatus, set_popup_warning } from '../../store/global/actions.global';
import UserManager from './manager/UserManager';
import { AnimatePresence } from 'framer-motion';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';

export interface UsersProps extends ServerProps {

}

interface UsersManager {
    initialize : boolean, 
    mode : "list" | "load", 
    add : boolean,
    update : {
        status : boolean,
        content : UserModel | {}
    }
}

export default function Users(props:UsersProps) {
    // * GLOBAL VARIABLES
    const dispatch = useDispatch()

    // * State variables
    const [manager, setManager] = useState<UsersManager>({
        add : false, 
        initialize : false, 
        mode : "load", 
        update : {
            status : false, 
            content : {}
        }
    })

    const [users, setUsers] = useState<UserModel[]>([])

    // * VARIABLES
    const tbody = users.map(user => {
        return {
            ...user, 
            complete_name : `${user.firstname} ${user.lastname}`,
            blocked : user.block_user ? "Oui" : "Non",
        }
    })

    // * FUNCTIONS
    const handleDelete = (data:any) => {
        const ids = data.map((el:any) => el.id_user)

        dispatch(setLoaderStatus({status : true}))
        return props.server.post('user/delete', {ids : ids})
        .then(res => {
            setUsers(state => state.filter((el:any) => !ids.includes(el.id_user)))
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
    }


    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : icons_config, 
        config : [
            // *
            {
                info : {
                    text : "Nom", 
                    name : "complete_name", 
                }, 
    
                style : {
                    width : "calc(100% - 650px)",
                }
            }, 
            {
                info : {
                    text : "Fonction", 
                    name : "function_user.name", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "E-mail", 
                    name : "mail", 
                }, 
                style : {
                    width : `200px`, 
                }
            }, 
            {
                info : {
                    text : "Téléphone", 
                    name : "phone", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Bloqué ?", 
                    name : "blocked", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setUsers,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add : () => setManager(state => {return{...state, add : true}}), 
                    delete : (data:any) => {
                        if(data.length === 0) {
                            return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner un utilisateur"}))
                        }
                        dispatch(set_popup_warning({
                            status : true, 
                            text : data.length === 1 ? "Voulez-vous vraiment supprimer cet utilisateur ?" : "Voulez-vous vraiment supprimer ces utilisateurs ?", 
                            handleYes : () => handleDelete(data)
                        }))


                    }
                }
            }
        }, 
        handleDoubleClick : (tr:any) => {
            setManager(state => {return{...state, update : {status : true, content : tr}}})
        }
    }

    // * USE EFFECTS 
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des utilisateurs"}))
            props.server.get('/user')
            .then(res => {
                setManager(state => {return{...state, initialize : true, mode : "list", selected_data : res.data.data[1]}})
                setUsers(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                setManager(state => {return{...state, initialize : true, mode : "list"}})
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
        }
    }, [manager.initialize])

    return (
        <React.Fragment>
            {/* // * USER-MANAGER */}
            <AnimatePresence>
            {
                manager.add || manager.update.status ?
                    <UserManager 
                        rerender={() => {
                            setUsers([])
                            setManager(state => {
                                return{...state, initialize : false
                            }})
                        }}
                        server = {props.server}
                        handleClose={() => setManager(state => {return{...state, add : false, update : {status : false, content : {}}}})}
                        setData = {setUsers}
                        update = {manager.update}
                    />
                : 
                    false
            }
            </AnimatePresence>

            <BaseApp>
                <BaseContainer nameClass = "users">
                    <ContainerLoad load = {manager.mode === "load"}>
                            <Table {...table_config} />
                    </ContainerLoad>
                </BaseContainer>
            </BaseApp>
            
        </React.Fragment>
    )
}