//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

// * SOCKET CONFIG ///////////``
//export const socket_server = io('http://localhost:4000', { transports : ['polling']});
export const socket_server = io('https://mynyomi.fr', { transports : ['polling']});

// * NODEJS SERVER ///////////
export const server_host = (route:string="") => { return `https://mynyomi.fr/admin/${route}` }
//export const server_host = (route:string="") => { return `http://localhost:4000/admin/${route}`}


// * NODEJS SERVER ///////////
export const server_public = (route:string="") => { return `https://mynyomi.fr/api/${route}`}
//export const server_public = (route:string="") => { return `http://localhost:4000/${route}`}
