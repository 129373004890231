import React from 'react' ;
import { Icons } from 'tc-minibox';
import { icons_config } from '../../../config/context.config';
import { AnimatePresence, motion } from 'framer-motion';

export interface ListElement {
    id : string |  number,
    name : string,
    text : string, 
}

export interface DropDownProps {
    reference : string,
    hover : any, 
    setHover : React.Dispatch<React.SetStateAction<any>>,
    value : any, 
    setValue : React.Dispatch<React.SetStateAction<any>>,
    list : ListElement[], 
}

export default function DropDown(props:DropDownProps) {
    /*
        PROPS : 
            - reference
            - hover, 
            - setHover, 
            - data, 
            - setData, 
            - list => (keys = ['id', 'name', 'text'])
    */
    return (
        <div 
            className='dropdown'
            onMouseEnter={() => { props.setHover(props.reference)}}
            onMouseLeave={() => { props.setHover(null)}}
    >
        <p>
            {props.list.find((el) => el.name === props.value)?.text}
        </p>
        <div 
            style = {{transform : `rotate(${props.hover === props.reference ? "180deg" : "0"})`}}
            className='tc-icons'
        >
            <Icons name = "chevron-up" {...icons_config}/>
        </div>
        <AnimatePresence>
        {
            props.hover === props.reference ? 
                <motion.div 
                    exit = {{height : 0, opacity : 0., transition : {delay : 0.2}}}
                    animate = {{height : "auto", opacity : 1}}
                    initial = {{height : 0, opacity : 0.}}
                    //transition = {{duration : 0.1}}
                    className='dropdown--list'

                >   
                    {
                        props.list.filter(el => el.name !== props.value).map((el, position)=> (
                                <motion.div 
                                    exit = {{scale : 0}}
                                    animate = {{scale : 1, transition : {delay : 0.1}}}
                                    initial = {{scale : 0}}
                                    //whileHover = {{scale : 1.1}}
                                    //whileTap = {{scale : 0.90}}
                                    onClick = {() => {
                                        props.setHover(null)
                                        props.setValue(el)
                                    }}
                                    key = {el.id}
                                >

                                    {el.text}
                                </motion.div>
  
                        ))
                    }
                </motion.div>
            : 
                null
        }
        </AnimatePresence>
    </div>
    )
}