import { useEffect, useState } from "react";
import {AnimatePresence, motion} from 'framer-motion' ; 
import BasePopup from "../../../tools/components/general/BasePopup";
import { IFunctionsUser } from "../functionsuser.types";
import DropDown from "../../../tools/components/general/DropDown";
import { modules_links } from "../../../config/context.config";
import { Icons } from "tc-minibox";
import MainButton from "../../../tools/components/general/MainButton";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import { DataType } from "tc-minibox/dist/types/table/index.table";
import { v4 } from "uuid";
import { ServerProps } from "../../../config/server.config";

export interface FuncUserManProps extends ServerProps {
    initialize? : IFunctionsUser | {}
    update? : {
        status : boolean,
        content : IFunctionsUser | {}
    }
    handleClose : () => void, 
    data : any,
    setData : (val:any) => void, 
    rerender? : () => void, 
} 


export default function FuncUserMan(props:FuncUserManProps) {
    // * VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [form, setForm] = useState<IFunctionsUser>({
        name : "", 
        token : v4(), 
        access : [], 
    })
    const [events, setEvents] = useState({
        hover : false, 
    })

    // * FUNCTIONS
    const handleAddAccess = (e:any) => {
        if(e.name === "all") {
            return setForm(state => {
                return {
                    ...state, 
                    access : [
                        ...state.access, 
                        ...modules_links.filter(mod => !form.access.map(el => el.name).includes(mod.name)).map(mod => {return {name : mod.name, sub_access : []}})
                    ]
                }
            })
        }

        return setForm(state => {
            return {
                ...state, 
                access : [
                    ...state.access, 
                    {name : e.name, sub_access : []}
                ]
            }
        })
    }

    const handleRemoveAccess = (e:any) => {
        return setForm(state => {
            return {
                ...state, 
                access : state.access.filter(access => access.name !== e.name)
            }
        })
    }

    const handleSubmit = () => {
        if(form.name === "") return dispatch(addRequestStatus({status : false, message : "Veuillez renseigner le nom de la fonction"}))
        if(form.access.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez assigner au moins un accès à la fonction"}))

        dispatch(setLoaderStatus({status : true}))

        if(props.update?.status) {
            return props.server.post('function-user/update', form)
            .then(res => {
                props.setData(res.data.data)
                props.handleClose()
                if(props.rerender) {props.rerender()}
                dispatch(setLoaderStatus({status : false}))
                return dispatch(addRequestStatus(res.data))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus(err.response.data))
            })
        }

        return props.server.post('function-user/add', form)
        .then(res => {
            props.setData(res.data.data)
            props.handleClose()
            if(props.rerender) {props.rerender()}
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })

    }

    // * USE EFFECT 
    useEffect(() => {
        if(props.update?.status) {
            setForm(props.update.content as IFunctionsUser)
        }
        if(props.initialize) {
            setForm(props.initialize as IFunctionsUser)
        }
    }, [props.update, props.initialize])

    return (
        <BasePopup
            nameClass="function-user-man"
            handleClose = {props.handleClose}
        >
            <div>
                <label htmlFor="name">Nom de la fonction</label>
                <input 
                    type="text" 
                    name="name" 
                    id="name" 
                    placeholder="Ex : Employé shop"
                    value = {form.name}
                    onChange = {(e) => setForm(state => {return {...state, name : e.target.value}})}
                />
            </div>
            <div>
                <label htmlFor="access">Accès</label>
                {
                    modules_links.filter(mod => !form.access.map(el => el.name).includes(mod.name)).length !== 0 ? 
                        <DropDown
                            reference = {"MODULES"}
                            hover = {events.hover}
                            setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                            value = {"choose"}
                            setValue = {(e) => handleAddAccess(e)}
                            list = {[
                                {id : "092739", name  : "all", text : "Tous"}, 
                                ...modules_links.filter(e => !form.access.map(el => el.name).includes(e.name)), 
                                {id : "091389", name  : "choose", text : "Choisir"}

                            ]}
                        />
                    : 
                        null
                }
 
                <div className = "selected_modules">
                    <AnimatePresence>
                    {
                        form.access.map((access, index) => {
                            const access_data = modules_links.find(module => module.name === access.name)
                            return (
                                <motion.div 
                                    exit={{scale: 0}}
                                    initial={{scale: 0}}
                                    animate={{scale: 1, transition: {delay : 0.2}}}
                                    className = "selected_module" 
                                    key = {index}
                                >
                                    <motion.div 
                                        whileHover={{scale: 1.1}}
                                        whileTap={{scale: 0.9}}
                                        onClick = {() => handleRemoveAccess(access_data)}
                                        className = 'remove'
                                    >
                                        <Icons name = "xmark" mode = {"duotone"} color = "#C3281B" />
                                    </motion.div>
                                    <p>{access_data?.text}</p>
                                </motion.div>
                            )
                        })
                    }
                    </AnimatePresence>
                </div>

                <MainButton
                    handleClick={handleSubmit}
                >
                    {props.update?.status ? "Modifier" : "Ajouter"}
                </MainButton>

            </div>
            
        </BasePopup>
    )
}