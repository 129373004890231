

export interface UserPersonalInfosProps {
    data : any,
    handleChangeInput : (e:React.ChangeEvent<HTMLInputElement>, reg?:string|RegExp) => void,
}

export default function UserPersonalInfos(props:UserPersonalInfosProps) {
    return (
        <div className="personal-infos">
            <div className ="personal-infos-element">
                <div>
                    <label htmlFor="lastname">Nom</label>
                    <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="Doe"
                        value={props.data.lastname}
                        onChange={(e) => props.handleChangeInput(e, /[^a-zA-Z ]/g)}
                    />
                </div>
                <div>
                    <label htmlFor="firstname">Prénom</label>
                    <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder="John"
                        value={props.data.firstname}
                        onChange={(e) => props.handleChangeInput(e, /[^a-zA-Z ]/g)}
                    />
                </div>

            </div>

            <div className ="personal-infos-element">
                <div>
                    <label htmlFor="mail">E-mail</label>
                    <input
                        type="text"
                        name="mail"
                        id="mail"
                        placeholder="john.doe@example.com"
                        value={props.data.mail}
                        onChange={(e) => props.handleChangeInput(e)}
                    />
                </div>
                <div>
                    <label htmlFor="phone">Téléphone</label>
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="0692345678"
                        value={props.data.phone}
                        onChange={(e) => props.handleChangeInput(e, /[^0-9]/g)}
                    />
                </div>
            </div>
        </div>
    )
}