import { Axios } from 'axios';
import { AuthType } from '../../store/types.store';
import Homepage from '../base/Homepage';
import Organizations from '../organizations/Organizations';
import Users from '../users/Users';
import FunctionsUser from '../functions_user/FunctionsUser';

export interface NavigationProps {
    server : Axios
}

export interface NavigationParams extends NavigationProps {
    account : AuthType
}

type NavigationItem = {
    id : string,
    id_menu : number,
    content : string,
    svg : string,
    path : string,
    component ?: JSX.Element
}

export const navigation = (props:NavigationProps):NavigationItem[] => {
    return [
        {id : 'N-01', id_menu : 1, content : "Tableau de bord", svg : "home", path : '/', component : <Homepage {...props}/>}, 
        {id : 'N-02', id_menu : 2, content : "Utilisateurs", svg : "users", path : '/users', component : <Users {...props} />}, 
        {id : 'N-03', id_menu : 3, content : "Fonctions utilisateurs", svg : "id-badge", path : '/functions', component : <FunctionsUser {...props} />}, 
    ]
}

export const navigation_data = (props:NavigationParams) => {
    if(props.account.infos.user_group === "direction") {
        return navigation({server : props.server})
    }
    // ! A CONFIGURER
    //const access_id = props.account.infos.access.map((acc:any) => acc.id_menu)
    //return navigation({server : props.server}).filter(nav => [1, ...access_id].includes(nav.id_menu) )

    return navigation({server : props.server})
}