import { useEffect, useState } from "react";
import { ServerProps } from "../../../config/server.config";
import { v4 } from "uuid";
import BasePopup from "../../../tools/components/general/BasePopup";
import CategorizerManager from "../../../tools/components/general/Categorizer";
import { UserModel } from "../../../database/user-model.db";
import UserPersonalInfos from "./grids/UserPersonalInfos";
import UserFunction from "./grids/UserFunction";
import MainButton from "../../../tools/components/general/MainButton";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";


export interface UserManagerProps extends ServerProps {
    update? : {
        status : boolean,
        content : UserModel | {}
    }
    rerender? : () => void, 
    handleClose : () => void,
    setData : React.Dispatch<React.SetStateAction<UserModel[]>>
}



export default function UserManager(props:UserManagerProps) {
    // * GLOBAL VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [user, setUser] = useState<UserModel>({
        token_user: v4(), 
        id_func: null,
        permission_type : "utilisateur", 
        lastname: "", 
        firstname: "",
        block_user : 0, 
        mail: "",
        phone: "",
    })

    // * FUNCTIONS
    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:string|RegExp = '') => {
        const value = e.target.value.replace(reg, '')

        setUser(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const handleSubmit = () => {
        if(user.firstname.length === 0 || user.lastname.length === 0 || user.mail.length === 0 || user.phone.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez remplir tous les champs"}))
        }

        if(user.id_func === null) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez choisir une fonction utilisateur"}))
        }
        dispatch(setLoaderStatus({status : true}))
        return props.server.post('user/add', user)
        .then(res => {
            props.setData(state => {return[...state, res.data.data]})
            if(props.rerender) {props.rerender()}
            props.handleClose()
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
    }

    const handleUpdate = () => {
        if(user.firstname.length === 0 || user.lastname.length === 0 || user.mail.length === 0 || user.phone.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez remplir tous les champs"}))
        }

        if(user.id_func === null) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez choisir une fonction utilisateur"}))
        }
        dispatch(setLoaderStatus({status : true}))
        return props.server.post('user/update', user)
        .then(res => {
            props.setData(state => {return[...state, res.data.data]})
            if(props.rerender) {props.rerender()}
            props.handleClose()
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
    }

    useEffect(() => {
        if(props.update?.status) {
            setUser(props.update.content as UserModel)
        }
        /*if(props.initialize) {
            setForm(props.initialize as IFunctionsUser)
        }*/
    }, [props.update])

    // * CONFIG COMPONENTS
    const config_categorizer = [
        {
            id : "MOD-00", 
            name : "Informations personnelles", 
            svg : "users",
            component : <UserPersonalInfos 
                            data = {user}
                            handleChangeInput = {handleChangeInput}
                        />,
        }, 
        {
            id : "MOD-01", 
            name : "Fonction utilisateur", 
            svg : "id-badge",
            component : <UserFunction 
                            data = {user}
                            setData = {setUser}
                            server = {props.server}
                            update = {props.update}
                        />,
        }, 

    ]
    return (
        <BasePopup nameClass="user-manager" handleClose={props.handleClose}>
            <CategorizerManager config = {config_categorizer} />

            {
                props.update?.status ?
                    <MainButton
                        handleClick = {handleUpdate}
                    >   
                        Modifier
                    </MainButton>
                :
                    <MainButton 
                        handleClick = {handleSubmit}
                    >
                        Enregistrer
                    </MainButton>
            }

        </BasePopup>
    )
}