import { compagny, icons_config } from '../../config/context.config';
import {motion} from 'framer-motion' ; 
import {Link, useLocation} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { account_user } from '../../store/account/selector.account';
import { navigation_data } from './data.nav';
import { ServerProps } from '../../config/server.config';
import { globalSelector } from '../../store/global/selector.global';
import { set_nav_warning, set_popup_warning } from '../../store/global/actions.global';
import { Icons } from 'tc-minibox';




export default function Navigation(props:ServerProps) {
    const dispatch = useDispatch()
    const account = useSelector(account_user)
    const global = useSelector(globalSelector)
    ////////////// VARIABLES //////////////
    const location = useLocation()

    return (
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            className="navigation">

            {/* /// LOGO /// */}
            <div className="logo">
                <div className="normal">{compagny.logo}</div>
                <div className="minified">
                    <img src = {compagny.logo_min} alt = "Logo minified"/>
                </div>
            </div>

            {/* /// LISTING NAVIGATION /// */}
            <div 
                className="navigation__container"
            >
                {
                    navigation_data({account : account, server : props.server}).map(el => (
                        <div 
                            key = {el.id}
                        >
                            <Link className={location.pathname === el.path ? "active" : ""} to = {el.path} >
                                <Icons name = {el.svg} mode = "duotone" color = {location.pathname === el.path ? "white" : icons_config.color}/>
                                <p><span>{el.content}</span></p>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </motion.div>
    )
}
