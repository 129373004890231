import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'; 
import { useSelector } from 'react-redux';
import { container_homepage, intro_homepage } from './animations/homepage.animations';
import {ReactComponent as OpenInTab} from '../../assets/svg/icons/square-arrow-up-right-duotone.svg'
import { account_user } from '../../store/account/selector.account';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { ServerProps } from '../../config/server.config';
import BaseApp from '../../tools/components/general/BaseApp';
import { modules_links } from '../../config/context.config';

export interface HomepageProps extends ServerProps {

}


export default function Homepage(props:HomepageProps) {
    const account = useSelector(account_user)

    return (
        <BaseApp>
            <BaseContainer nameClass = "homepage">
                {/* /// INTRODUCTION /// */}
                <motion.div 
                    exit = "exit"
                    animate = "animate"
                    initial = "initial"
                    variants={intro_homepage}
                    className='intro'
                >
                    <h1>Bienvenue {account.infos.firstname} {account.infos.lastname},</h1>
                    <p>Ravi de vous revoir !</p>
                </motion.div>

                {/* /// CONTAINER (BUTTONS + SEARCH) /// 
                <motion.div 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants={container_homepage}
                    className='container'
                >
                    <div className='module-links'>
                        <h1>Modules</h1>
                        <div>
                        {
                            modules_links.map((mod, _) => (
                                <motion.div
                                    whileHover={{scale : 1.02}}
                                    whileTap = {{scale : 0.98}}
                                    key = {mod.id}
                                >
                                    {mod.svg}
                                    <p>
                                        <span>{mod.text}</span>
                                        <span>{mod.subdomain}</span>
                                    </p>
                                    <div className = "open-in-tab"><OpenInTab /></div>
                                </motion.div>
                            ))
                        }
                        </div>
                    </div>
                </motion.div>
                */}
            </BaseContainer>
        </BaseApp>

    )
}

/*                    <motion.div>
                        <Icons name = "magnifying-glass" {...icons_config} />
                        <input type = "text" placeholder='Rechercher une affaire' />
                    </motion.div>

*/