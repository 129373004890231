/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "tc-minibox";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { icons_config } from "../../config/context.config";
import { DataType, TableForProps } from "tc-minibox/dist/types/table/index.table";
import React, { useEffect, useState } from "react";
import FuncUserMan, { FuncUserManProps } from "./layouts/FuncUserMan";
import { AnimatePresence } from "framer-motion";
import { ServerProps } from "../../config/server.config";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../store/global/actions.global";
import { IFunctionsUser } from "./functionsuser.types";



export interface FunctionsUserProps extends ServerProps {

}

export interface FunctionsUserManager {
    initialize : boolean, 
    mode : "list" | "load", 
    add : boolean,
    update : {
        status : boolean,
        content : IFunctionsUser | {}
    }
}

export default function FunctionsUser(props:FunctionsUserProps) {
    // * GENERAL VARIABLES 
    const dispatch = useDispatch()

    // * STATES
    const [functions, setFunctions] = useState<DataType>([])

    const [manager, setManager] = useState<FunctionsUserManager>({
        add : false, 
        initialize : false, 
        mode : "load", 
        update : {
            status : false, 
            content : {}
        }
    })

    // * VARIABLES 
    const tbody = functions.map((func:any) => {
        return {
            ...func, 
            nb_access : `${func.access.length} accès autorisé${func.access.length > 1 ? "s" : ""}`
        }
    })

    // * Initialize component : get all organizations
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true}))
            props.server.get('function-user')
            .then((res:any) => {
                setManager(state => {return{...state, initialize : true}})
                setFunctions(res.data.data)
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus(err.response.data))
            })
        }
    }, [manager.initialize])

    // * FUNCTIONS 
    const handleDelete = (data:any) => {
        const ids = data.map((el:any) => el.id)

        dispatch(setLoaderStatus({status : true}))
        return props.server.post('function-user/delete', {ids : ids})
        .then(res => {
            setFunctions(state => state.filter((el:any) => !ids.includes(el.id)))
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
    }

    // * COMPONENTS CONFIG
    const table_config:TableForProps= {
        height : 40, 
        editing : false, 
        icons : icons_config, 
        config : [
            // *
            {
                info : {
                    text : "Nom", 
                    name : "name", 
                }, 
    
                style : {
                    width : "calc(100% - 250px)",
                }
            }, 
            {
                info : {
                    text : "Nombre d'accès", 
                    name : "nb_access", 
                }, 
                style : {
                    width : `250px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setFunctions,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add : () => setManager(state => {return{...state, add : true}}), 
                    delete : (data:any) => {
                        if(data.length === 0) {
                            return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner une fonction utilisateur"}))
                        }
                        dispatch(set_popup_warning({
                            status : true, 
                            text : data.length === 1 ? "Voulez-vous vraiment supprimer cette fonction utilisateur ?" : "Voulez-vous vraiment supprimer ces fonctions utilisateurs ?", 
                            handleYes : () => handleDelete(data)
                        }))
                    }
                }
            }
        }, 
        handleDoubleClick : (tr:any) => {
            setManager(state => {return{...state, update : {status : true, content : tr}}})
        }
    }

    const func_user_man_config:FuncUserManProps = {
        server : props.server,
        handleClose : () => setManager(state => {return{...state, add : false, update : {status : false, content : {}}}}), 
        data : functions, 
        setData : (val:any) => setFunctions(state => [...state, val]), 
        update : manager.update, 
        rerender : () => {
            setFunctions([])
            setManager(state => {return{...state, initialize : false}})
        }
    }

    return (
        <BaseApp>
            <AnimatePresence>
                {
                    manager.add || manager.update.status ? 
                        <FuncUserMan {...func_user_man_config} />
                    : 
                        null
                }
            </AnimatePresence>
            
            <BaseContainer nameClass = "functions-user">
                <Table {...table_config} />
            </BaseContainer>
        </BaseApp>
    )
}