import axios from "axios";
import {BrowserRouter, Routes, Route} from 'react-router-dom' ; 
import { server_host, socket_server } from "../config/server.config";
import { useSelector, useDispatch } from "react-redux";
import { account_user } from "../store/account/selector.account";
import { logout } from "../store/account/actions.account";
import ErrorNotFound from "./base/ErrorNotFound";
import UnderConstruction from "./base/UnderConstruction";
import { useEffect, useState } from "react";
import InfoAccount from "./base/InfoAccount";
import { setLoaderStatus } from "../store/global/actions.global";
import Navigation from "./nav/Nav";
import { navigation_data } from "./nav/data.nav";


export interface MainManager {
    settings : boolean
}

export default function Main() {
    // * REDUX HOOKS
    const dispatch = useDispatch()
    const account = useSelector(account_user)
    const server = axios.create({
        baseURL: server_host(),
    });
    const [manager, setManager] = useState({
        settings : false
    })

    // * AXIOS INTERCEPTORS
    server.interceptors.request.use(
        config => {
          const token = account.token;
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
    );

    // Add a response interceptor to handle 401 Unauthorized errors
    server.interceptors.response.use(
        response => {
        return response;
        },
        error => {
        if (error.response && error.response.status === 401) {
            dispatch(logout())
        }
        return Promise.reject(error);
        }
    );

    useEffect(() => {
        // Écoutez l'événement "deposit-status-res" côté client
        socket_server.on('update-loader-message', (data) => {
            dispatch(setLoaderStatus({status : true, message : data}))
        });
    }, [socket_server])

    return (
        <BrowserRouter>
        <InfoAccount setManager = {setManager}/>
        <Navigation server = {server} />
          <Routes>
              {
                  navigation_data({account, server}).map(nd => (
                      <Route key = {`Routes-${nd.id}`} path = {nd.path} element = {nd.component ? nd.component : <UnderConstruction />} />   
                  ))
              }
              <Route path = '*' element = {<ErrorNotFound/>} />
          </Routes>
        </BrowserRouter>
    )
}