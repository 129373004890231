import React, { useState } from "react"
import SearchSelect from "../../../../tools/components/general/SearchSelect"
import { ServerProps } from "../../../../config/server.config"
import { UserModel } from "../../../../database/user-model.db"
import { AnimatePresence } from "framer-motion"
import FuncUserMan, { FuncUserManProps } from "../../../functions_user/layouts/FuncUserMan"
import { v4 } from "uuid"
import MainButton from "../../../../tools/components/general/MainButton"
import BooleanButton from "../../../../tools/components/general/BooleanButton"



export interface UserFunctionProps extends ServerProps {
    data : UserModel, 
    setData : React.Dispatch<React.SetStateAction<UserModel>>, 
    update? : {
        status : boolean,
        content : UserModel | {}
    }
}

export interface UserFunctionManager {
    add : {
        status : boolean, 
        content : {} | any
    }
}

export default function UserFunction(props:UserFunctionProps) {
    const [manager, setManager] = useState<UserFunctionManager>({
        add : {
            status : false, 
            content : {}
        }
    })

    const func_user_man_config:FuncUserManProps = {
        initialize : manager.add.content, 
        server : props.server,
        handleClose : () => setManager(state => {return{...state, add : {status : false, content : {}}}}), 
        data : props.data.id_func, 
        setData : (val:any) => props.setData(state => {return {...state, id_func : val.id}}), 
    }
    


    return (
        <React.Fragment>
            <AnimatePresence>
            {
                manager.add.status ? 
                    <FuncUserMan {...func_user_man_config} />
                : 
                    null
            }
            </AnimatePresence>
            <div className="user-function">
                <div>
                    <label htmlFor="function-user">Fonction utilisateur</label>
                    <SearchSelect
                            {...{
                                adding : true, 
                                handleAdd : (e:any) => setManager((state:any) => {return{...state, add : {status : true, content : {token : v4(), name : e, access : []}}}}),
                                text_no_data : "Aucune fonction trouvée", 
                                handleClick : (e) => props.setData((state:any) => {return {...state, id_func : e.id}}),
                                handleDelete : () => {},
                                no_data_text : "Aucune affaire trouvée", 
                                server : {
                                    axios : props.server,
                                    route : "/function-user/search"
                                },
                                value : props.data.id_func,
                            }}
                    />
                </div>
                {
                    props.update?.status ? 
                        <div className="block_user">
                            <BooleanButton 
                                state = {props.data.block_user === 1 ? true : false}
                                setState = {(val) => props.setData(state => {return{...state, block_user : val ? 1 : 0}})}
                            />
                            <label htmlFor = 'block-user'>Utilisateur bloqué ?</label>

                        </div>
                    : 
                        null
                }

            </div>
        </React.Fragment>
    )
}